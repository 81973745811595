@charset "UTF-8";

@import "config";
@import "mixin";

.cmn-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  border-bottom: 1px solid #e5e5e5;
  /* .cmn-header-rest */
  &-rest {
    .cmn-header--container {
      min-width: 0;
    }
    .m-section--inner {
      width: 100%;
      max-width: $content_width;
    }
    .cmn-header--logo {
      @include sp {
        width: 65px;
      }
    }
    .cmn-header--nav {
      width: 56px;
      @include sp {
          width: 50px;
      }
    }
    .cmn-header--icon_list {
      width: 100%;
    }
    .cmn-header--icon_link {
      &.is_show {
        .cmn-header--icon_text {
          &:before {
            margin-bottom: 0;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .cmn-header--icon_text {
      &:before {
        width: 28px;
        height: 28px;
        @include sp {
          width: 20px;
          height: 20px;
        }
      }
    }
    .cmn-header--icon_inline {
      display: none;
    }
  }


  /* .cmn-header--container */
  &--container {
    position: relative;
    background-color: #fff;
    min-width: 1140px;
    @include sp {
      min-width: 0;
    }
    &>.m-section--inner {
      @include sp {
        padding-right: 0;
      }
    }
  }

  /* .cmn-header--box */
  &--box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    position: relative;
    @include sp {
      height: 65px;
    }
  }
	/* .cmn-header--logo */
	&--logo {
    width: 158px;
    @include sp {
      width: 135px;
    }
	}
  /* .cmn-header--link */
  &--link {
    display: block;
    &:hover {
      @include hover-opacity;
    }
  }
  /* .cmn-header--pagettl-rest */
  &--pagettl-rest {
    width: 228px;
    @include sp {
      width: 138px;
    }
  }
  /* .cmn-header--pagettl-shoku */
  &--pagettl-shoku {
    width: 179px;
    @include sp {
      width: 120px;
    }
  }
	/* .cmn-header--nav */
	&--nav {
    width: calc(100% - 158px - 24px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sp {
      width: 150px;
    }
	}
	/* .cmn-header--nav_list */
	&--nav_list {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 710px;
    @include sp {
      display: none;
    }
	}
  /* .cmn-header--nav_item */
  &--nav_item {
    text-align: center;
  }
  /* .cmn-header--nav_link */
  &--nav_link {
    width: 90px;
    display: block;
    font-feature-settings: "palt";
    &:hover {
      @include hover-text_link;
    }
    &:before {
      content: "";
      display: block;
      width: 36px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      margin: 0 auto 5px;
    }
    /* .cmn-header--nav_link-topics */
    &-topics {
      &:before {
        background-image: url(../images/cmn-header-nav-link1.svg);
      }
    }
    /* .cmn-header--nav_link-shopnews */
    &-shopnews {
      width: 100px;
      &:before {
        background-image: url(../images/cmn-header-nav-link2.svg);
      }
    }
    /* .cmn-header--nav_link-syokusai */
    &-syokusai {
      &:before {
        background-image: url(../images/cmn-header-nav-link3.svg);
      }
    }
    /* .cmn-header--nav_link-restaurant */
    &-restaurant {
      width: 110px;
      &:before {
        background-image: url(../images/cmn-header-nav-link4.svg);
      }
    }
    /* .cmn-header--nav_link-floorguide */
    &-floorguide {
      &:before {
        background-image: url(../images/cmn-header-nav-link5.svg);
      }
    }
    /* .cmn-header--nav_link-kaigi */
    &-kaigi {
      &:before {
        background-image: url(../images/cmn-header-nav-link6.svg);
      }
    }
    /* .cmn-header--nav_link-access */
    &-access {
      &:before {
        background-image: url(../images/cmn-header-nav-link7.svg);
      }
    }
    /* .cmn-header--nav_link-contact */
    &-contact {
      &:before {
        background-image: url(../images/cmn-header-nav-link8.svg);
      }
    }
  }
	/* .cmn-header--icon_list */
	&--icon_list {
    width: 168px;
    display: flex;
    justify-content: space-between;
    transition: .5s all;
    @include sp {
      width: 100%;
    }
	}
	/* .cmn-header--icon_item */
	&--icon_item {
    width: 56px;
    @include sp {
      width: 50px;
      &:not(.pc) {
        display: block;
      }
    }
	}
	/* .cmn-header--icon_link */
	&--icon_link {
    display: block;
    margin: auto;
    height: 90px;
    position: relative;
    @include sp {
      height: 65px;
    }
    &:hover{
      @include hover-opacity;
      .cmn-header--icon_inline {
        @include hover-underline;
      }
    }
    &.is_show {  // ヘッダーメニューオープン時
      background-color: #000;
      &:hover {
        opacity: 1;
        .cmn-header--icon_text {
          @include hover-opacity;
        }
      }
      .cmn-header--icon_text {
        transition: .3s all;
        backface-visibility: hidden;
        &:before {
          background-image: url(../images/cmn-header-icon-text-close.svg);
          margin-bottom: -8px;
          position: relative;
        }
      }
    }
	}

	/* .cmn-header--icon_text */
	&--icon_text {
    font-size: 11px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'Cormorant Garamond', serif;
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 auto 5px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
    /* .cmn-header--icon_text-info */
    &-info {
      &:before {
        background-image: url(../images/cmn-header-icon-text-info.svg);
      }
    }
    /* .cmn-header--icon_text-search */
    &-search {
      &:before {
        background-image: url(../images/cmn-header-icon-text-search.svg);
      }
    }
    /* .cmn-header--icon_text-lang */
    &-lang {
      &:before {
        background-image: url(../images/cmn-header-icon-text-lang.svg);
      }
    }
    /* .cmn-header--icon_text-spmenu */
    &-spmenu {
      &:before {
        background-image: url(../images/cmn-header-icon-text-spmenu.svg);
      }
    }
	}
}


.cmn-slidemenu {
  width: 100%;
  background-color: #000;
  padding-top: 60px;
  color: #fff;
  position: relative;
  display: none;
  min-width: 1140px;
  @include sp {
    padding-top: 30px;
    min-width: 0;
  }
  /* .cmn-slidemenu--box */
  &--box {
    background-image: url(../images/m-bg-black.png);
    padding: 64px 0;
    @include sp {
      padding: 30px 0;
    }
  }
  /* .cmn-slidemenu--area */
  &--area{
    padding: 0 56px;
    @include sp {
      padding: 0;
    }
  }
  /* .cmn-slidemenu--close_btn */
  &--close_btn {
    height: 60px;
    position: relative;
    .fa-chevron-up {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 18px;
      padding: 20px;
      box-sizing: border-box;
      @include sp {
        width: 100%;
        text-align: center;
      }
      &:hover {
        @include hover-opacity;
      }
    }
  }
}
.cmn-infomenu {
	/* .cmn-infomenu--box */
	&--box {
    margin-top: 60px;
    @include sp {
      margin-top: 30px;
    }
	}
	/* .cmn-infomenu--subtitle */
	&--subtitle {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    @include sp {
      font-size: 16px;
    }
	}
	/* .cmn-infomenu--hour_list */
	&--hour_list {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @include sp {
      display: block;
      margin-top: 15px;
    }
	}
	/* .cmn-infomenu--hour_item */
	&--hour_item {
    width: 320px;
    @include sp {
      width: 100%;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 0;
      }
    }
	}
	/* .cmn-infomenu--area_list */
	&--area_list {
    background-color: #fff;
    border: 3px solid #fff;
    text-align: center;
	}
	/* .cmn-infomenu--area_term */
	&--area_term {
    background-color: $primary_color;
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
    color: #fff;
    @include sp {
      font-size: 14px;
      line-height: 30px;
    }
	}
	/* .cmn-infomenu--area_description */
	&--area_description {
    position: relative;
    height: 80px;
    @include sp {
      height: 60px;
    }
	}
  /* .cmn-infomenu--area_text */
  &--area_text {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    @include sp {
      line-height: 0.9;
    }
    /* .cmn-infomenu--area_text-s */
    &-s {
      font-size: 13px;
    }
  }
  /* .cmn-infomenu--hour_description */
  &--hour_description {
    margin-top: 20px;
    @include sp {
      margin-top: 10px;
      font-size: 12px;
    }
  }
	/* .cmn-infomenu--service_list */
	&--service_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    @include sp {
      margin-top: 15px;
    }
	}
	/* .cmn-infomenu--service_item */
	&--service_item {
    width: 480px;
    margin-top: 20px;
    @include sp {
      width: 100%;
      margin-top: 10px;
    }
    &:nth-of-type(-n+2) {
      margin-top: 0;
      @include sp {
        margin-top: 10px;
      }
    }
    &:first-of-type {
      @include sp {
        margin-top: 0;
      }
    }
	}
	/* .cmn-infomenu--service_link */
	&--service_link {
    background-color: #1a1a1a;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 55px;
    &:not(span) {
      &:hover {
        &:before {
          @include hover-saturate;
        }
        .cmn-infomenu--service_text {
          @include hover-underline;
        }
      }
    }
    &:after {
      @include sp {
        @include font-awesome("\f054");
        position: absolute;
        top: 50%;
        right: 10px;
        color: #fff;
        transform: translateY(-50%);
      }
    }
    &:before {
      content: "";
      display: block;
      background-color: $primary_color;
      width: 55px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      transition: .3s all;
    }
    /* .cmn-infomenu--service_link-info */
    &-info {
      &:before {
        background-image: url(../images/cmn-infomenu-service-link-info.svg);
      }
    }
    /* .cmn-infomenu--service_link-baby */
    &-baby {
      &:before {
        background-image: url(../images/cmn-infomenu-service-link-baby.svg);
      }
    }
    /* .cmn-infomenu--service_link-handicap */
    &-handicap {
      &:before {
        background-image: url(../images/cmn-infomenu-service-link-handicap.svg);
      }
    }
    /* .cmn-infomenu--service_link-facility */
    &-facility {
      &:before {
        background-image: url(../images/cmn-infomenu-service-link-facility.svg);
      }
    }
    /* .cmn-infomenu--service_link-point */
    &-point {
      &:before {
        background-image: url(../images/cmn-infomenu-service-link-point.svg);
      }
    }
	}
  /* .cmn-infomenu--service_text */
  &--service_text {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px 10px 72px;
    display: inline-block;
    width: 100%;
    @include sp {
      font-size: 16px;
    }
  }
  /* .cmn-infomenu--btn_box */
  &--btn_box {
    margin-top: 48px;
  }
}

.cmn-searchmenu {
	/* .cmn-searchmenu--box */
	&--box {
    margin-top: 40px;
    .m-shopsearch--term {
      &:before {
        background-image: url(../images/m-shopsearch-list-white.svg);
      }
    }
	}
}

.cmn-slidemenu-lang {
  width: 170px;
  padding: 20px 0;
  position: absolute;
  top: 90px;
  right: calc((100% - 1140px) / 2 + 20px);
  min-width: 0;
}
.cmn-splangmenu {
	/* .cmn-splangmenu--link */
	&--link {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    display: block;
    text-align: center;
    line-height: 36px;
    &:hover {
      opacity: 1;
      @include hover-bgcolor;
    }
	}
  /* .cmn-splangmenu--caution */
  &--caution {
    color: #b2b2b2;
    font-size: 12px;
    margin-top: 30px;
    padding: 0 12px;
  }
}

.cmn-spmenu {
  /* .cmn-spmenu--list */
  &--list {
    width: 250px;
    margin: auto;
  }
  /* .cmn-spmenu--item */
  &--item {
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  /* .cmn-spmenu--link */
  &--link {
    color: #fff;
    font-size: 15px;
    &:hover {
      @include hover-text_link;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 36px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      vertical-align: middle;
      margin-right: 10px;
    }
    /* .cmn-spmenu--link-topics */
    &-topics {
      &:before {
        background-image: url(../images/cmn-header-nav-link1.svg);
      }
    }
    /* .cmn-spmenu--link-shopnews */
    &-shopnews {
      &:before {
        background-image: url(../images/cmn-header-nav-link2.svg);
      }
    }
    /* .cmn-spmenu--link-syokusai */
    &-syokusai {
      &:before {
        background-image: url(../images/cmn-header-nav-link3.svg);
      }
    }
    /* .cmn-spmenu--link-restaurant */
    &-restaurant {
      &:before {
        background-image: url(../images/cmn-header-nav-link4.svg);
      }
    }
    /* .cmn-spmenu--link-floorguide */
    &-floorguide {
      &:before {
        background-image: url(../images/cmn-header-nav-link5.svg);
      }
    }
    /* .cmn-spmenu--link-kaigi */
    &-kaigi {
      &:before {
        background-image: url(../images/cmn-header-nav-link6.svg);
      }
    }
    /* .cmn-spmenu--link-access */
    &-access {
      &:before {
        background-image: url(../images/cmn-header-nav-link7.svg);
      }
    }
    /* .cmn-spmenu--link-contact */
    &-contact {
      &:before {
        background-image: url(../images/cmn-header-nav-link8.svg);
      }
    }
  }
	/* .cmn-spmenu--lang_box */
	&--lang_box {
    padding: 40px 15px;
    text-align: center;
    font-weight: 500;
	}
	/* .cmn-spmenu--lang_term */
	&--lang_term {
    color: #b3b3b3;
	}
	/* .cmn-spmenu--lang_description */
	&--lang_description {
    margin-top: 20px;
	}
	/* .cmn-spmenu--lang_list */
	&--lang_list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
	}
	/* .cmn-spmenu--lang_item */
	&--lang_item {
    margin-top: 18px;
    &:after {
      content: "/";
      margin-right: 5px;
    }
    &:nth-of-type(-n+2) {
      width: 50%;
      margin-top: 0;
    }
    &:first-of-type {
      text-align: right;
    }
    &:nth-of-type(2) {
      text-align: left;
    }
    &:last-of-type {
      &:after {
        content: none;
      }
    }
	}
	/* .cmn-spmenu--lang_link */
	&--lang_link {
    color: #fff;
    &:hover {
      @include hover-text_link;
    }
	}
  /* .cmn-spmenu--lang_caution */
  &--lang_caution {
    font-size: 12px;
    margin-top: 30px;
    color: #b2b2b2;
  }
}

.cmn-footer {
	/* .cmn-footer--top_box */
	&--top_box {
    background-color: #1a1a1a;
    padding: 40px 0;
	}
	/* .cmn-footer--icon_list */
	&--icon_list {
    display: flex;
    justify-content: center;
	}
	/* .cmn-footer--icon_item */
	&--icon_item {
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0;
    }
	}
	/* .cmn-footer--icon_link */
	&--icon_link {
    display: block;
    width: 40px;
    height: 40px;
    background-color: $primary_color;
    border-radius: 50%;
    position: relative;
    &:hover {
      @include hover-saturate;
    }
	}
	/* .cmn-footer--icon_img */
	&--icon_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	}
	/* .cmn-footer--center_box */
	&--center_box {
    padding: 36px 0;
	}
	/* .cmn-footer--info_list */
	&--info_list {
    width: calc(100% - 12px);
    margin: auto;
    @include sp {
      width: calc(100% - 50px);
    }
    .slick-slide {
      width: 260px;
      margin: 0 7px;
      @include sp {
        width: calc(100vw - 80px);
        margin: 0;
      }
    }
    .slick-arrow {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background-color: transparent;
      transform: translateY(-50%);
      transition: .3s all;
      &:hover {
        @include hover-opacity;
      }
    }
    .slick-prev {
      left: -25px;
      @include sp {
        left: -30px;
      }
      &:after {
        @include font-awesome("\f053");
        color: #999;
        font-size: 16px;
        padding: 10px 5px;
        @include sp {
          font-size: 18px;
        }
      }
    }
    .slick-next {
      right: -25px;
      @include sp {
        right: -30px;
      }
      &:after {
        @include font-awesome("\f054");
        color: #999;
        font-size: 16px;
        padding: 10px 5px;
        @include sp {
          font-size: 18px;
        }
      }
    }
	}
	/* .cmn-footer--info_item */
	&--info_item {
    border: 1px solid #e6e6e6;
    @include sp {
      box-sizing: border-box;
    }
	}
	/* .cmn-footer--info_link */
	&--info_link {
    display: block;
    &:hover {
      @include hover-opacity;
    }
	}
	/* .cmn-footer--info_img */
	&--info_img {
    width: 100%;
	}
  /* .cmn-footer--bottom_box */
	&--bottom_box {
    background-color: $gray;
    padding: 20px 0;
	}
	/* .cmn-footer--nav_list */
	&--nav_list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
	}
	/* .cmn-footer--nav_item */
	&--nav_item {
    margin-right: 30px;
    @include sp {
      width: 50%;
      margin-right: 0;
      text-align: center;
    }
    &:last-of-type {
      margin-right: 0;
    }
	}
	/* .cmn-footer--nav_link */
	&--nav_link {
    position: relative;
    padding-left: 15px;
    @include sp {
      font-size: 12px;
      padding-left: 0;
      line-height: 2;
    }
    &:hover {
      @include hover-text_link;
    }
    &:before {
      @include font-awesome("\f054");
      position: absolute;
      top: 50%;
      left: 0;
      color: #333;
      font-size: 12px;
      transform: translateY(-50%);
      @include sp {
        content: none;
      }
      @include sp {
        right: 10px;
        font-size: 8px;
      }
    }
    /* .cmn-footer--nav_link-open */
    &-open {
      &:after {
        content: "";
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 15px;
        height: 15px;
        display: inline-block;
        background-image: url(../images/icon-open-red.svg);
        margin-left: 8px;
        vertical-align: -3px;
      }
    }
	}
	/* .cmn-footer--logo_box */
	&--logo_box {
    margin-top: 60px;
    @include sp {
      margin-top: 30px;
    }
	}
  /* .cmn-footer--logo_area */
  &--logo_area {
    text-align: center;
    margin-top: 30px;
    @include sp {
      margin-top: 25px;
    }
    &:first-of-type {
      margin-top: 0;
    }
  }
  /* .cmn-footer--logo_link */
  &--logo_link {
    &:hover {
      @include hover-opacity;
    }
  }
	/* .cmn-footer--copyright_box */
	&--copyright_box {
    margin-top: 30px;
    text-align: center;
    @include sp {
      margin-top: 15px;
    }
	}
	/* .cmn-footer--copyright_text */
	&--copyright_text {
    font-size: 12px;
	}
}


.cmn-aside {
  /* .cmn-asede--sidenav */
	&--sidenav {
    position: fixed;
    top: 50%;
    right: -5px;
    transition: .3s all;
    z-index: 999;
    @include sp {
      top: 70%;
      right: 0;
    }
    &:hover {
      right: 0;
    }
	}
	/* .cmn-header--sidenav_link */
	&--sidenav_link {
    background-color: #000;
    width: 45px;
    height: 140px;
    border-radius: 5px 0 0 5px;
    display: block;
    position: relative;
    @include sp {
      width: 40px;
      height: 40px;
      background-color: $primary_color;
      border-radius: 0 0 0 5px;
    }
	}
	/* .cmn-header--sidenav_img */
	&--sidenav_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	}
  /* .cmn-header--pagetop */
  &--pagetop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    @include sp {
      bottom: auto;
      top: calc(70% - 40px);
      right: 0;
    }
  }
  /* .cmn-header--pagetop_link*/
  &--pagetop_link {
    width: 50px;
    height: 50px;
    display: block;
    background-color: #000;
    border-radius: 5px;
    position: relative;
    opacity: 0;
    @include sp {
      width: 40px;
      height: 40px;
      border-radius: 5px 0 0 0;
      opacity: 1;
    }
    &:before {
      @include font-awesome("\f077");
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      transform: translate(-50%, -50%);
    }
    &.js-show {
      opacity: 1;
    }
  }
}


.cmn-modal {
  display: none;
  position: relative;
  z-index: 9999;

  /* .cmn-modal--overlay */
  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .cmn-modal--box */
  &--box {
    max-width: 960px;
    max-height: 675px;
    background-color: #fff;
    border-radius: 10px;
    padding: 48px;
    @include sp {
      padding: 48px 15px;
      margin: 0 15px;
    }
  }
  /* .cmn-moodal--logo */
	&--logo {
    @include sp {
      text-align: center;
    }
	}
  /* .cmn-modal--logo-link */
  &--logo-link {
    &:hover {
      @include hover-opacity;
    }
  }
	/* .cmn-modal--explain */
	&--explain {
    margin-top: 40px;
    border: 1px solid #ccc;
    width: 800px;
    padding: 70px;
    font-size: 16px;
    @include sp {
      margin-top: 30px;
      font-size: 14px;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
	}
	/* .cmn-modal--explain_text */
	&--explain_text {
    margin-top: 40px;
    @include sp {
      margin-top: 30px;
    }
    &:first-of-type {
      margin-top: 0;
    }
	}
	/* .cmn-modal--explain_link */
	&--explain_link {
    color: #036eb8;
    text-decoration: underline;
    margin-left: 10px;
    &:hover {
      @include hover-text_link;
    }
	}
  /* .cmn-modal--btn */
  &--btn {
    margin-top: 40px !important;
  }
}

.goog-te-banner-frame.skiptranslate {
  display: none;
}

.skiptranslate {
  display: none;
}



//langunageボタン非表示　表示の時は以下をそのまま削除すること！
// #languageMenu {
//   display: none;
// }
// .cmn-header--icon_list {
//   justify-content: flex-end;
// }
// .cmn-spmenu--lang_box {
//   display: none;
// }
