@charset "UTF-8";
.cmn-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  border-bottom: 1px solid #e5e5e5;
  /* .cmn-header-rest */
  /* .cmn-header--container */
  /* .cmn-header--box */
  /* .cmn-header--logo */
  /* .cmn-header--link */
  /* .cmn-header--pagettl-rest */
  /* .cmn-header--pagettl-shoku */
  /* .cmn-header--nav */
  /* .cmn-header--nav_list */
  /* .cmn-header--nav_item */
  /* .cmn-header--nav_link */
  /* .cmn-header--icon_list */
  /* .cmn-header--icon_item */
  /* .cmn-header--icon_link */
  /* .cmn-header--icon_text */
}

.cmn-header-rest .cmn-header--container {
  min-width: 0;
}

.cmn-header-rest .m-section--inner {
  width: 100%;
  max-width: 1140px;
}

@media (max-width: 425px) {
  .cmn-header-rest .cmn-header--logo {
    width: 65px;
  }
}

.cmn-header-rest .cmn-header--nav {
  width: 56px;
}

@media (max-width: 425px) {
  .cmn-header-rest .cmn-header--nav {
    width: 50px;
  }
}

.cmn-header-rest .cmn-header--icon_list {
  width: 100%;
}

.cmn-header-rest .cmn-header--icon_link.is_show .cmn-header--icon_text:before {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
}

.cmn-header-rest .cmn-header--icon_text:before {
  width: 28px;
  height: 28px;
}

@media (max-width: 425px) {
  .cmn-header-rest .cmn-header--icon_text:before {
    width: 20px;
    height: 20px;
  }
}

.cmn-header-rest .cmn-header--icon_inline {
  display: none;
}

.cmn-header--container {
  position: relative;
  background-color: #fff;
  min-width: 1140px;
}

@media (max-width: 425px) {
  .cmn-header--container {
    min-width: 0;
  }
}

@media (max-width: 425px) {
  .cmn-header--container > .m-section--inner {
    padding-right: 0;
  }
}

.cmn-header--box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  position: relative;
}

@media (max-width: 425px) {
  .cmn-header--box {
    height: 65px;
  }
}

.cmn-header--logo {
  width: 158px;
}

@media (max-width: 425px) {
  .cmn-header--logo {
    width: 135px;
  }
}

.cmn-header--link {
  display: block;
}

.cmn-header--link:hover {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-header--link:hover {
    opacity: 1;
  }
}

.cmn-header--pagettl-rest {
  width: 228px;
}

@media (max-width: 425px) {
  .cmn-header--pagettl-rest {
    width: 138px;
  }
}

.cmn-header--pagettl-shoku {
  width: 179px;
}

@media (max-width: 425px) {
  .cmn-header--pagettl-shoku {
    width: 120px;
  }
}

.cmn-header--nav {
  width: calc(100% - 158px - 24px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 425px) {
  .cmn-header--nav {
    width: 150px;
  }
}

.cmn-header--nav_list {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  width: 710px;
}

@media (max-width: 425px) {
  .cmn-header--nav_list {
    display: none;
  }
}

.cmn-header--nav_item {
  text-align: center;
}

.cmn-header--nav_link {
  width: 90px;
  display: block;
  font-feature-settings: "palt";
  /* .cmn-header--nav_link-topics */
  /* .cmn-header--nav_link-shopnews */
  /* .cmn-header--nav_link-syokusai */
  /* .cmn-header--nav_link-restaurant */
  /* .cmn-header--nav_link-floorguide */
  /* .cmn-header--nav_link-kaigi */
  /* .cmn-header--nav_link-access */
  /* .cmn-header--nav_link-contact */
}

.cmn-header--nav_link:hover {
  text-decoration: underline;
  color: #e8382f;
}

@media (max-width: 425px) {
  .cmn-header--nav_link:hover {
    text-decoration: inherit;
    color: inherit;
  }
}

.cmn-header--nav_link:before {
  content: "";
  display: block;
  width: 36px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 auto 5px;
}

.cmn-header--nav_link-topics:before {
  background-image: url(../images/cmn-header-nav-link1.svg);
}

.cmn-header--nav_link-shopnews {
  width: 100px;
}

.cmn-header--nav_link-shopnews:before {
  background-image: url(../images/cmn-header-nav-link2.svg);
}

.cmn-header--nav_link-syokusai:before {
  background-image: url(../images/cmn-header-nav-link3.svg);
}

.cmn-header--nav_link-restaurant {
  width: 110px;
}

.cmn-header--nav_link-restaurant:before {
  background-image: url(../images/cmn-header-nav-link4.svg);
}

.cmn-header--nav_link-floorguide:before {
  background-image: url(../images/cmn-header-nav-link5.svg);
}

.cmn-header--nav_link-kaigi:before {
  background-image: url(../images/cmn-header-nav-link6.svg);
}

.cmn-header--nav_link-access:before {
  background-image: url(../images/cmn-header-nav-link7.svg);
}

.cmn-header--nav_link-contact:before {
  background-image: url(../images/cmn-header-nav-link8.svg);
}

.cmn-header--icon_list {
  width: 168px;
  display: flex;
  justify-content: space-between;
  transition: .5s all;
}

@media (max-width: 425px) {
  .cmn-header--icon_list {
    width: 100%;
  }
}

.cmn-header--icon_item {
  width: 56px;
}

@media (max-width: 425px) {
  .cmn-header--icon_item {
    width: 50px;
  }
  .cmn-header--icon_item:not(.pc) {
    display: block;
  }
}

.cmn-header--icon_link {
  display: block;
  margin: auto;
  height: 90px;
  position: relative;
}

@media (max-width: 425px) {
  .cmn-header--icon_link {
    height: 65px;
  }
}

.cmn-header--icon_link:hover {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-header--icon_link:hover {
    opacity: 1;
  }
}

.cmn-header--icon_link:hover .cmn-header--icon_inline {
  text-decoration: underline;
}

@media (max-width: 425px) {
  .cmn-header--icon_link:hover .cmn-header--icon_inline {
    text-decoration: inherit;
  }
}

.cmn-header--icon_link.is_show {
  background-color: #000;
}

.cmn-header--icon_link.is_show:hover {
  opacity: 1;
}

.cmn-header--icon_link.is_show:hover .cmn-header--icon_text {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-header--icon_link.is_show:hover .cmn-header--icon_text {
    opacity: 1;
  }
}

.cmn-header--icon_link.is_show .cmn-header--icon_text {
  transition: .3s all;
  backface-visibility: hidden;
}

.cmn-header--icon_link.is_show .cmn-header--icon_text:before {
  background-image: url(../images/cmn-header-icon-text-close.svg);
  margin-bottom: -8px;
  position: relative;
}

.cmn-header--icon_text {
  font-size: 11px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Cormorant Garamond', serif;
  /* .cmn-header--icon_text-info */
  /* .cmn-header--icon_text-search */
  /* .cmn-header--icon_text-lang */
  /* .cmn-header--icon_text-spmenu */
}

.cmn-header--icon_text:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto 5px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.cmn-header--icon_text-info:before {
  background-image: url(../images/cmn-header-icon-text-info.svg);
}

.cmn-header--icon_text-search:before {
  background-image: url(../images/cmn-header-icon-text-search.svg);
}

.cmn-header--icon_text-lang:before {
  background-image: url(../images/cmn-header-icon-text-lang.svg);
}

.cmn-header--icon_text-spmenu:before {
  background-image: url(../images/cmn-header-icon-text-spmenu.svg);
}

.cmn-slidemenu {
  width: 100%;
  background-color: #000;
  padding-top: 60px;
  color: #fff;
  position: relative;
  display: none;
  min-width: 1140px;
  /* .cmn-slidemenu--box */
  /* .cmn-slidemenu--area */
  /* .cmn-slidemenu--close_btn */
}

@media (max-width: 425px) {
  .cmn-slidemenu {
    padding-top: 30px;
    min-width: 0;
  }
}

.cmn-slidemenu--box {
  background-image: url(../images/m-bg-black.png);
  padding: 64px 0;
}

@media (max-width: 425px) {
  .cmn-slidemenu--box {
    padding: 30px 0;
  }
}

.cmn-slidemenu--area {
  padding: 0 56px;
}

@media (max-width: 425px) {
  .cmn-slidemenu--area {
    padding: 0;
  }
}

.cmn-slidemenu--close_btn {
  height: 60px;
  position: relative;
}

.cmn-slidemenu--close_btn .fa-chevron-up {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .cmn-slidemenu--close_btn .fa-chevron-up {
    width: 100%;
    text-align: center;
  }
}

.cmn-slidemenu--close_btn .fa-chevron-up:hover {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-slidemenu--close_btn .fa-chevron-up:hover {
    opacity: 1;
  }
}

.cmn-infomenu {
  /* .cmn-infomenu--box */
  /* .cmn-infomenu--subtitle */
  /* .cmn-infomenu--hour_list */
  /* .cmn-infomenu--hour_item */
  /* .cmn-infomenu--area_list */
  /* .cmn-infomenu--area_term */
  /* .cmn-infomenu--area_description */
  /* .cmn-infomenu--area_text */
  /* .cmn-infomenu--hour_description */
  /* .cmn-infomenu--service_list */
  /* .cmn-infomenu--service_item */
  /* .cmn-infomenu--service_link */
  /* .cmn-infomenu--service_text */
  /* .cmn-infomenu--btn_box */
}

.cmn-infomenu--box {
  margin-top: 60px;
}

@media (max-width: 425px) {
  .cmn-infomenu--box {
    margin-top: 30px;
  }
}

.cmn-infomenu--subtitle {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 425px) {
  .cmn-infomenu--subtitle {
    font-size: 16px;
  }
}

.cmn-infomenu--hour_list {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

@media (max-width: 425px) {
  .cmn-infomenu--hour_list {
    display: block;
    margin-top: 15px;
  }
}

.cmn-infomenu--hour_item {
  width: 320px;
}

@media (max-width: 425px) {
  .cmn-infomenu--hour_item {
    width: 100%;
    margin-top: 10px;
  }
  .cmn-infomenu--hour_item:first-of-type {
    margin-top: 0;
  }
}

.cmn-infomenu--area_list {
  background-color: #fff;
  border: 3px solid #fff;
  text-align: center;
}

.cmn-infomenu--area_term {
  background-color: #e8382f;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  color: #fff;
}

@media (max-width: 425px) {
  .cmn-infomenu--area_term {
    font-size: 14px;
    line-height: 30px;
  }
}

.cmn-infomenu--area_description {
  position: relative;
  height: 80px;
}

@media (max-width: 425px) {
  .cmn-infomenu--area_description {
    height: 60px;
  }
}

.cmn-infomenu--area_text {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  /* .cmn-infomenu--area_text-s */
}

@media (max-width: 425px) {
  .cmn-infomenu--area_text {
    line-height: 0.9;
  }
}

.cmn-infomenu--area_text-s {
  font-size: 13px;
}

.cmn-infomenu--hour_description {
  margin-top: 20px;
}

@media (max-width: 425px) {
  .cmn-infomenu--hour_description {
    margin-top: 10px;
    font-size: 12px;
  }
}

.cmn-infomenu--service_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

@media (max-width: 425px) {
  .cmn-infomenu--service_list {
    margin-top: 15px;
  }
}

.cmn-infomenu--service_item {
  width: 480px;
  margin-top: 20px;
}

@media (max-width: 425px) {
  .cmn-infomenu--service_item {
    width: 100%;
    margin-top: 10px;
  }
}

.cmn-infomenu--service_item:nth-of-type(-n+2) {
  margin-top: 0;
}

@media (max-width: 425px) {
  .cmn-infomenu--service_item:nth-of-type(-n+2) {
    margin-top: 10px;
  }
}

@media (max-width: 425px) {
  .cmn-infomenu--service_item:first-of-type {
    margin-top: 0;
  }
}

.cmn-infomenu--service_link {
  background-color: #1a1a1a;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 55px;
  /* .cmn-infomenu--service_link-info */
  /* .cmn-infomenu--service_link-baby */
  /* .cmn-infomenu--service_link-handicap */
  /* .cmn-infomenu--service_link-facility */
  /* .cmn-infomenu--service_link-point */
}

.cmn-infomenu--service_link:not(span):hover:before {
  filter: saturate(150%);
}

@media (max-width: 425px) {
  .cmn-infomenu--service_link:not(span):hover:before {
    filter: none;
  }
}

.cmn-infomenu--service_link:not(span):hover .cmn-infomenu--service_text {
  text-decoration: underline;
}

@media (max-width: 425px) {
  .cmn-infomenu--service_link:not(span):hover .cmn-infomenu--service_text {
    text-decoration: inherit;
  }
}

@media (max-width: 425px) {
  .cmn-infomenu--service_link:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 700;
    position: absolute;
    top: 50%;
    right: 10px;
    color: #fff;
    transform: translateY(-50%);
  }
}

.cmn-infomenu--service_link:before {
  content: "";
  display: block;
  background-color: #e8382f;
  width: 55px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: .3s all;
}

.cmn-infomenu--service_link-info:before {
  background-image: url(../images/cmn-infomenu-service-link-info.svg);
}

.cmn-infomenu--service_link-baby:before {
  background-image: url(../images/cmn-infomenu-service-link-baby.svg);
}

.cmn-infomenu--service_link-handicap:before {
  background-image: url(../images/cmn-infomenu-service-link-handicap.svg);
}

.cmn-infomenu--service_link-facility:before {
  background-image: url(../images/cmn-infomenu-service-link-facility.svg);
}

.cmn-infomenu--service_link-point:before {
  background-image: url(../images/cmn-infomenu-service-link-point.svg);
}

.cmn-infomenu--service_text {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px 10px 72px;
  display: inline-block;
  width: 100%;
}

@media (max-width: 425px) {
  .cmn-infomenu--service_text {
    font-size: 16px;
  }
}

.cmn-infomenu--btn_box {
  margin-top: 48px;
}

.cmn-searchmenu {
  /* .cmn-searchmenu--box */
}

.cmn-searchmenu--box {
  margin-top: 40px;
}

.cmn-searchmenu--box .m-shopsearch--term:before {
  background-image: url(../images/m-shopsearch-list-white.svg);
}

.cmn-slidemenu-lang {
  width: 170px;
  padding: 20px 0;
  position: absolute;
  top: 90px;
  right: calc((100% - 1140px) / 2 + 20px);
  min-width: 0;
}

.cmn-splangmenu {
  /* .cmn-splangmenu--link */
  /* .cmn-splangmenu--caution */
}

.cmn-splangmenu--link {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  text-align: center;
  line-height: 36px;
}

.cmn-splangmenu--link:hover {
  opacity: 1;
  background-color: #e8382f;
  color: #fff;
}

@media (max-width: 425px) {
  .cmn-splangmenu--link:hover {
    background-color: inherit;
    color: inherit;
  }
}

.cmn-splangmenu--caution {
  color: #b2b2b2;
  font-size: 12px;
  margin-top: 30px;
  padding: 0 12px;
}

.cmn-spmenu {
  /* .cmn-spmenu--list */
  /* .cmn-spmenu--item */
  /* .cmn-spmenu--link */
  /* .cmn-spmenu--lang_box */
  /* .cmn-spmenu--lang_term */
  /* .cmn-spmenu--lang_description */
  /* .cmn-spmenu--lang_list */
  /* .cmn-spmenu--lang_item */
  /* .cmn-spmenu--lang_link */
  /* .cmn-spmenu--lang_caution */
}

.cmn-spmenu--list {
  width: 250px;
  margin: auto;
}

.cmn-spmenu--item {
  margin-top: 20px;
}

.cmn-spmenu--item:first-of-type {
  margin-top: 0;
}

.cmn-spmenu--link {
  color: #fff;
  font-size: 15px;
  /* .cmn-spmenu--link-topics */
  /* .cmn-spmenu--link-shopnews */
  /* .cmn-spmenu--link-syokusai */
  /* .cmn-spmenu--link-restaurant */
  /* .cmn-spmenu--link-floorguide */
  /* .cmn-spmenu--link-kaigi */
  /* .cmn-spmenu--link-access */
  /* .cmn-spmenu--link-contact */
}

.cmn-spmenu--link:hover {
  text-decoration: underline;
  color: #e8382f;
}

@media (max-width: 425px) {
  .cmn-spmenu--link:hover {
    text-decoration: inherit;
    color: inherit;
  }
}

.cmn-spmenu--link:before {
  content: "";
  display: inline-block;
  width: 36px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.cmn-spmenu--link-topics:before {
  background-image: url(../images/cmn-header-nav-link1.svg);
}

.cmn-spmenu--link-shopnews:before {
  background-image: url(../images/cmn-header-nav-link2.svg);
}

.cmn-spmenu--link-syokusai:before {
  background-image: url(../images/cmn-header-nav-link3.svg);
}

.cmn-spmenu--link-restaurant:before {
  background-image: url(../images/cmn-header-nav-link4.svg);
}

.cmn-spmenu--link-floorguide:before {
  background-image: url(../images/cmn-header-nav-link5.svg);
}

.cmn-spmenu--link-kaigi:before {
  background-image: url(../images/cmn-header-nav-link6.svg);
}

.cmn-spmenu--link-access:before {
  background-image: url(../images/cmn-header-nav-link7.svg);
}

.cmn-spmenu--link-contact:before {
  background-image: url(../images/cmn-header-nav-link8.svg);
}

.cmn-spmenu--lang_box {
  padding: 40px 15px;
  text-align: center;
  font-weight: 500;
}

.cmn-spmenu--lang_term {
  color: #b3b3b3;
}

.cmn-spmenu--lang_description {
  margin-top: 20px;
}

.cmn-spmenu--lang_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cmn-spmenu--lang_item {
  margin-top: 18px;
}

.cmn-spmenu--lang_item:after {
  content: "/";
  margin-right: 5px;
}

.cmn-spmenu--lang_item:nth-of-type(-n+2) {
  width: 50%;
  margin-top: 0;
}

.cmn-spmenu--lang_item:first-of-type {
  text-align: right;
}

.cmn-spmenu--lang_item:nth-of-type(2) {
  text-align: left;
}

.cmn-spmenu--lang_item:last-of-type:after {
  content: none;
}

.cmn-spmenu--lang_link {
  color: #fff;
}

.cmn-spmenu--lang_link:hover {
  text-decoration: underline;
  color: #e8382f;
}

@media (max-width: 425px) {
  .cmn-spmenu--lang_link:hover {
    text-decoration: inherit;
    color: inherit;
  }
}

.cmn-spmenu--lang_caution {
  font-size: 12px;
  margin-top: 30px;
  color: #b2b2b2;
}

.cmn-footer {
  /* .cmn-footer--top_box */
  /* .cmn-footer--icon_list */
  /* .cmn-footer--icon_item */
  /* .cmn-footer--icon_link */
  /* .cmn-footer--icon_img */
  /* .cmn-footer--center_box */
  /* .cmn-footer--info_list */
  /* .cmn-footer--info_item */
  /* .cmn-footer--info_link */
  /* .cmn-footer--info_img */
  /* .cmn-footer--bottom_box */
  /* .cmn-footer--nav_list */
  /* .cmn-footer--nav_item */
  /* .cmn-footer--nav_link */
  /* .cmn-footer--logo_box */
  /* .cmn-footer--logo_area */
  /* .cmn-footer--logo_link */
  /* .cmn-footer--copyright_box */
  /* .cmn-footer--copyright_text */
}

.cmn-footer--top_box {
  background-color: #1a1a1a;
  padding: 40px 0;
}

.cmn-footer--icon_list {
  display: flex;
  justify-content: center;
}

.cmn-footer--icon_item {
  margin-right: 24px;
}

.cmn-footer--icon_item:last-of-type {
  margin-right: 0;
}

.cmn-footer--icon_link {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #e8382f;
  border-radius: 50%;
  position: relative;
}

.cmn-footer--icon_link:hover {
  filter: saturate(150%);
}

@media (max-width: 425px) {
  .cmn-footer--icon_link:hover {
    filter: none;
  }
}

.cmn-footer--icon_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cmn-footer--center_box {
  padding: 36px 0;
}

.cmn-footer--info_list {
  width: calc(100% - 12px);
  margin: auto;
}

@media (max-width: 425px) {
  .cmn-footer--info_list {
    width: calc(100% - 50px);
  }
}

.cmn-footer--info_list .slick-slide {
  width: 260px;
  margin: 0 7px;
}

@media (max-width: 425px) {
  .cmn-footer--info_list .slick-slide {
    width: calc(100vw - 80px);
    margin: 0;
  }
}

.cmn-footer--info_list .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-color: transparent;
  transform: translateY(-50%);
  transition: .3s all;
}

.cmn-footer--info_list .slick-arrow:hover {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-footer--info_list .slick-arrow:hover {
    opacity: 1;
  }
}

.cmn-footer--info_list .slick-prev {
  left: -25px;
}

@media (max-width: 425px) {
  .cmn-footer--info_list .slick-prev {
    left: -30px;
  }
}

.cmn-footer--info_list .slick-prev:after {
  font-family: "Font Awesome 5 Free";
  content: "";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 700;
  color: #999;
  font-size: 16px;
  padding: 10px 5px;
}

@media (max-width: 425px) {
  .cmn-footer--info_list .slick-prev:after {
    font-size: 18px;
  }
}

.cmn-footer--info_list .slick-next {
  right: -25px;
}

@media (max-width: 425px) {
  .cmn-footer--info_list .slick-next {
    right: -30px;
  }
}

.cmn-footer--info_list .slick-next:after {
  font-family: "Font Awesome 5 Free";
  content: "";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 700;
  color: #999;
  font-size: 16px;
  padding: 10px 5px;
}

@media (max-width: 425px) {
  .cmn-footer--info_list .slick-next:after {
    font-size: 18px;
  }
}

.cmn-footer--info_item {
  border: 1px solid #e6e6e6;
}

@media (max-width: 425px) {
  .cmn-footer--info_item {
    box-sizing: border-box;
  }
}

.cmn-footer--info_link {
  display: block;
}

.cmn-footer--info_link:hover {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-footer--info_link:hover {
    opacity: 1;
  }
}

.cmn-footer--info_img {
  width: 100%;
}

.cmn-footer--bottom_box {
  background-color: #f2f2f2;
  padding: 20px 0;
}

.cmn-footer--nav_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cmn-footer--nav_item {
  margin-right: 30px;
}

@media (max-width: 425px) {
  .cmn-footer--nav_item {
    width: 50%;
    margin-right: 0;
    text-align: center;
  }
}

.cmn-footer--nav_item:last-of-type {
  margin-right: 0;
}

.cmn-footer--nav_link {
  position: relative;
  padding-left: 15px;
  /* .cmn-footer--nav_link-open */
}

@media (max-width: 425px) {
  .cmn-footer--nav_link {
    font-size: 12px;
    padding-left: 0;
    line-height: 2;
  }
}

.cmn-footer--nav_link:hover {
  text-decoration: underline;
  color: #e8382f;
}

@media (max-width: 425px) {
  .cmn-footer--nav_link:hover {
    text-decoration: inherit;
    color: inherit;
  }
}

.cmn-footer--nav_link:before {
  font-family: "Font Awesome 5 Free";
  content: "";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 0;
  color: #333;
  font-size: 12px;
  transform: translateY(-50%);
}

@media (max-width: 425px) {
  .cmn-footer--nav_link:before {
    content: none;
  }
}

@media (max-width: 425px) {
  .cmn-footer--nav_link:before {
    right: 10px;
    font-size: 8px;
  }
}

.cmn-footer--nav_link-open:after {
  content: "";
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url(../images/icon-open-red.svg);
  margin-left: 8px;
  vertical-align: -3px;
}

.cmn-footer--logo_box {
  margin-top: 60px;
}

@media (max-width: 425px) {
  .cmn-footer--logo_box {
    margin-top: 30px;
  }
}

.cmn-footer--logo_area {
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 425px) {
  .cmn-footer--logo_area {
    margin-top: 25px;
  }
}

.cmn-footer--logo_area:first-of-type {
  margin-top: 0;
}

.cmn-footer--logo_link:hover {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-footer--logo_link:hover {
    opacity: 1;
  }
}

.cmn-footer--copyright_box {
  margin-top: 30px;
  text-align: center;
}

@media (max-width: 425px) {
  .cmn-footer--copyright_box {
    margin-top: 15px;
  }
}

.cmn-footer--copyright_text {
  font-size: 12px;
}

.cmn-aside {
  /* .cmn-asede--sidenav */
  /* .cmn-header--sidenav_link */
  /* .cmn-header--sidenav_img */
  /* .cmn-header--pagetop */
  /* .cmn-header--pagetop_link*/
}

.cmn-aside--sidenav {
  position: fixed;
  top: 50%;
  right: -5px;
  transition: .3s all;
  z-index: 999;
}

@media (max-width: 425px) {
  .cmn-aside--sidenav {
    top: 70%;
    right: 0;
  }
}

.cmn-aside--sidenav:hover {
  right: 0;
}

.cmn-aside--sidenav_link {
  background-color: #000;
  width: 45px;
  height: 140px;
  border-radius: 5px 0 0 5px;
  display: block;
  position: relative;
}

@media (max-width: 425px) {
  .cmn-aside--sidenav_link {
    width: 40px;
    height: 40px;
    background-color: #e8382f;
    border-radius: 0 0 0 5px;
  }
}

.cmn-aside--sidenav_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cmn-aside--pagetop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

@media (max-width: 425px) {
  .cmn-aside--pagetop {
    bottom: auto;
    top: calc(70% - 40px);
    right: 0;
  }
}

.cmn-aside--pagetop_link {
  width: 50px;
  height: 50px;
  display: block;
  background-color: #000;
  border-radius: 5px;
  position: relative;
  opacity: 0;
}

@media (max-width: 425px) {
  .cmn-aside--pagetop_link {
    width: 40px;
    height: 40px;
    border-radius: 5px 0 0 0;
    opacity: 1;
  }
}

.cmn-aside--pagetop_link:before {
  font-family: "Font Awesome 5 Free";
  content: "";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}

.cmn-aside--pagetop_link.js-show {
  opacity: 1;
}

.cmn-modal {
  display: none;
  position: relative;
  z-index: 9999;
  /* .cmn-modal--overlay */
  /* .cmn-modal--box */
  /* .cmn-moodal--logo */
  /* .cmn-modal--logo-link */
  /* .cmn-modal--explain */
  /* .cmn-modal--explain_text */
  /* .cmn-modal--explain_link */
  /* .cmn-modal--btn */
}

.cmn-modal--overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmn-modal--box {
  max-width: 960px;
  max-height: 675px;
  background-color: #fff;
  border-radius: 10px;
  padding: 48px;
}

@media (max-width: 425px) {
  .cmn-modal--box {
    padding: 48px 15px;
    margin: 0 15px;
  }
}

@media (max-width: 425px) {
  .cmn-modal--logo {
    text-align: center;
  }
}

.cmn-modal--logo-link:hover {
  opacity: .8;
}

@media (max-width: 425px) {
  .cmn-modal--logo-link:hover {
    opacity: 1;
  }
}

.cmn-modal--explain {
  margin-top: 40px;
  border: 1px solid #ccc;
  width: 800px;
  padding: 70px;
  font-size: 16px;
}

@media (max-width: 425px) {
  .cmn-modal--explain {
    margin-top: 30px;
    font-size: 14px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
}

.cmn-modal--explain_text {
  margin-top: 40px;
}

@media (max-width: 425px) {
  .cmn-modal--explain_text {
    margin-top: 30px;
  }
}

.cmn-modal--explain_text:first-of-type {
  margin-top: 0;
}

.cmn-modal--explain_link {
  color: #036eb8;
  text-decoration: underline;
  margin-left: 10px;
}

.cmn-modal--explain_link:hover {
  text-decoration: underline;
  color: #e8382f;
}

@media (max-width: 425px) {
  .cmn-modal--explain_link:hover {
    text-decoration: inherit;
    color: inherit;
  }
}

.cmn-modal--btn {
  margin-top: 40px !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none;
}

.skiptranslate {
  display: none;
}
